export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'Back',
      HOLD_HC:'Hold HC',
      HOLD_HHC:'Hold hHC',
      HOLD: 'HOLD',
      DAY: 'day',
      GET: 'GET',
      INNER_BALANCE: 'Inner Balance',
      HHC_TO_HC: 'hHC to HC',
      TAKE_HC:'TAKE HC',
      TAKE_HHC:'TAKE hHC', 
    },
  }
};
