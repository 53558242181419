export const locale = {
  lang: 'zh',
  data: {
    CONFIRM_GETHHC_MODAL: {
      TITLE: '注意',
      INFO: ' 您確定要取貨嗎',
      INFO_1: ' 接收以後 ',
      INFO_2: ' 持有將結束，您將無法獲得更高的',
      AGREEMENT: '我确认所有事情',
      YES: '是',
      NO: '否',
      QUESTION:'您確定要撿起來嗎'
    },
  },
};
