export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_HOLD_HC: {
      BACK: 'वापस',
      MAX: 'मैक्स',
      HOLD: 'पकड़ो',
      BALANCE: 'शेष राशि:',
      GET:"पाना",
      AVAILABLE:"उपलब्ध",
      YOU_GET:"तुमको मिल रहा है"
    },
  },
};
