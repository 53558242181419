export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'Kembali',
      HOLD_HC:'Tahan HC',
      HOLD_HHC:'Tahan hHC',
      HOLD: 'MEMEGANG',
      DAY: 'hari',
      GET: 'MENDAPATKAN',
      INNER_BALANCE: 'Keseimbangan Batin',
      HHC_TO_HC: 'hHC ke HC',
      TAKE_HC:'AMBIL HC',
      TAKE_HHC:'AMBIL hHC',
    },
  },
};
