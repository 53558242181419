import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { locale as russian } from './i18n/ru';
import { locale as english } from './i18n/en';
import { locale as chine } from './i18n/zh';
import { locale as hindi } from './i18n/hi';
import { locale as vietnam } from './i18n/vi';
import { locale as arab } from './i18n/id';
import { locale as indonesia } from './i18n/ar';
import { locale as urdu } from './i18n/ur';
import { locale as french } from './i18n/fr';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { HelpInfo, sideNavType } from 'app/auth/helpers';
import { WalletService } from 'app/auth/service/wallet.service';
import { SideNavService } from 'app/auth/service';
import { hHCBalance } from 'app/auth/helpers/variables';
import moment from 'moment';

@Component({
  selector: 'app-side-nav-hold-hc-wallet',
  templateUrl: './side-nav-hold-hc-wallet.component.html',
  styleUrls: ['./side-nav-hold-hc-wallet.component.scss']
})
export class SideNavHoldHCWalletComponent implements OnInit {
  isModalOpen = false;
  isLoading: false;
  hHCBalance = hHCBalance;
  getCurrenthHC ={ date: 0, timeForClock: 0, name: 'hHC-1', amount: 0, depositId: 0, img: 'hHC-1', entryLevel:0, currentEntryLevel:0 }
  holdHCIdList: number[] = []
  holdHCList = [];
  holdhHCList = [];
  depositList = [];
  holdhHCDay = [
    { day: 21, name: 'hHC-1' },
    { day: 42, name: 'hHC-2' },
    { day: 64, name: 'hHC-3' },
    { day: 88, name: 'hHC-4' },
    { day: 114, name: 'hHC-5' },
    { day: 142, name: 'hHC-6' },
    { day: 171, name: 'hHC-7' }, 
    { day: 200, name: 'hHC-8' }
  ]


  lastIndexNotZerohHCBalance = 0;
  iNothHC = true;

  constructor(
    private helpInfo: HelpInfo,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private walletService: WalletService,
    private coreTranslationService: CoreTranslationService,
    private sideNavService: SideNavService
  ) {
    this.coreTranslationService.translate(
      russian,
      english,
      chine,
      hindi,
      vietnam,
      arab,
      indonesia,
      urdu,
      french
    );
    this.helpInfo.isOpenSlideNavSecondary.subscribe.bind(this);
    this.getDeposit.bind(this)
  }

  async ngOnInit(): Promise<void> {
    this.getBalancehHC()
    this.getDeposit()


    this.helpInfo.isHold.subscribe(result => {
      if (result) {
        this.helpInfo.isHold.next(false);
        this.getDeposit();
        this.getBalancehHC();
      }
    })

    this.helpInfo.isGetHCOrhHC.subscribe(ansve => {
      if (ansve.result) {
       
        if(ansve.amount === 0 ){
          this.holdHCIdList = this.holdHCIdList.filter(x => { return ansve.depositId != x });
          this.holdHCList = this.holdHCList.filter(x => { return ansve.depositId != x.depositId });
          this.holdhHCList = this.holdhHCList.filter(x => { return ansve.depositId != x.depositId });
        } else {
          let HCIndex =  this.holdHCList.findIndex(x => { return ansve.depositId === x.depositId });
          let hHCIndex =  this.holdhHCList.findIndex(x => { return ansve.depositId === x.depositId });
          if(HCIndex > -1){
            this.holdHCList[HCIndex].amount = ansve.amount;
          } else {
            this.holdhHCList[hHCIndex].amount = ansve.amount;
          }
          
         
        }
        this.helpInfo.isGetHCOrhHC.next({depositId: -1,result:false,amount:-1});
        this.getBalancehHC()
      }
    })

  }

  getBalancehHC() {
    this.walletService.getBalancesOnPayment(this.helpInfo.userId.value).then(
      x => {
        
        x.hMfs.forEach((result, index) => {
      
          if (+result >= 0.01 ) {
            this.hHCBalance[index].value = Math.floor(+result *100) /100
            this.lastIndexNotZerohHCBalance = index;
            this.iNothHC =false
          }
        });
      },
      err => {
        console.log(err);
      })
  }

  async getDeposit() {
    this.holdHCIdList = await this.walletService.getDepositIds()
    let promiseArray = []

    this.holdHCIdList.forEach(id => {
      promiseArray.push(this.walletService.getDeposit(id))
    })

    Promise.all(promiseArray).then(result => {

      this.depositList = result;
      this.setInfoHoldHC()
    }).catch(err => {

    })
  }

  takeHC(depositId: number) {
    this.walletService.unHold(depositId).then(result => {
      this.holdHCIdList = this.holdHCIdList.filter(x => { return depositId != x });
      this.holdHCList = this.holdHCList.filter(x => { return depositId != x.depositId });
      this.holdhHCList = this.holdhHCList.filter(x => { return depositId != x.depositId });
      this.sideNavService.isCoinChange.next(true)
    }).catch(err => {

    })
  }



  async setInfoHoldHC() {
    this.holdHCList = [];
    this.holdhHCList = [];
    this.depositList.forEach( async (item, index) => {
      if(+item.amount < 0.01){
        return
      }
      let itemHoldHC = { date: 0, timeForClock: 0, name: 'hHC-1', amount: 0, depositId: 0, img: 'hHC-1', entryLevel:0, currentEntryLevel:0 }
      itemHoldHC.date = Math.floor((Date.now() - moment(item.createdAt).valueOf()) / 1000 / 60 / 60 / 24);
      itemHoldHC.currentEntryLevel = await this.walletService.getOutHHCLevelFromDeposit(this.holdHCIdList[index])
   
      if(item.entryLevel > 0){
        itemHoldHC.date +=  this.holdhHCDay[item.entryLevel-1].day;
      }
      
      let isSethHC = false
      for (let indexFor = item.entryLevel; indexFor < this.holdhHCDay.length; indexFor++) {
        if(isSethHC)
        break
        
        if (this.holdhHCDay[indexFor].day > +itemHoldHC.date) {
          itemHoldHC.timeForClock = this.holdhHCDay[indexFor].day - +itemHoldHC.date;
          isSethHC =true;
        }

      }
      if(itemHoldHC.currentEntryLevel === 0){
        itemHoldHC.name = this.holdhHCDay[itemHoldHC.currentEntryLevel].name;
      } else {
        itemHoldHC.name = this.holdhHCDay[itemHoldHC.currentEntryLevel-1].name;
      }
      
      itemHoldHC.amount = Math.floor (+item.amount* 100 ) / 100  ;
      itemHoldHC.depositId = this.holdHCIdList[index];
      if(item.entryLevel > 0){
        itemHoldHC.entryLevel = item.entryLevel
        
        itemHoldHC.img = 'hHC-'+ item.entryLevel;
       
        this.holdhHCList.push(itemHoldHC)
      } else {
        
        itemHoldHC.img = 'forcecoin';
        this.holdHCList.push(itemHoldHC)
      }
     
    })
  }

  openModal(coin){
    
    this.getCurrenthHC = coin;
    this.isModalOpen = true
  }

  onSucceed(event: any) {
    this.takeHC(this.getCurrenthHC.depositId)
    // this.getHHC(this.getCurrenthHC.depositId,this.getCurrenthHC.amount + '');
  }

  openHoldHC(ishHC, coin?, isHold = true) {
    
    if(!isHold){
      this.getCurrenthHC = coin;
      
      this.helpInfo.isOpenSlideNavThird.next({
        isOpen: true,
        type: sideNavType.holdHC,
        item:{ishHC, coin,isHold}
      });
      return
    }
    
    this.helpInfo.isOpenSlideNavThird.next({
      isOpen: true,
      type: sideNavType.holdHC,
      item:{ishHC,isHold}
    });
  }
  

  closeSlideNavSecondary(isOpen) {
    if (!isOpen) {
      this.helpInfo.isOpenSlideNavSecondary.next({
        isOpen: false,
        type: sideNavType.social,
      });
    }
  }

  closeALLSlideNav(isOpen) {
    if (!isOpen) {
      this.helpInfo.isOpenSlideNavSecondary.next({
        isOpen: false,
        type: sideNavType.walletHistory,
      });
      this.helpInfo.isOpenSlideNavMain.next({
        isOpen: false,
        type: sideNavType.menu,
      });
      this.helpInfo.isOpenSlideNavThird.next({
        isOpen: false,
        type: sideNavType.inner,
      });
    }
  }

}
