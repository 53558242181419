export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_HOLD_HC: {
      BACK: 'Назад',
      MAX: 'Макс',
      HOLD: 'Холд',
      BALANCE: 'БАЛАНС:',
      GET:"Получить",
      AVAILABLE:"Доступно",
      YOU_GET:"Вы получаете"
    },
  },
};
