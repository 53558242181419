export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'خلف',
      HOLD_HC:'عقد HC',
      HOLD_HHC:'عقد hHC',
      HOLD: 'يمسك',
      DAY: 'يوم',
      GET: 'يحصل',
      INNER_BALANCE: 'التوازن الداخلي',
      HHC_TO_HC: 'HC إلى hHC',
      TAKE_HC:'خذ HC',
      TAKE_HHC:'خذ hHC',
    },
  },
};
