export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_SWAP_HHC_ON_HC: {
      BACK: 'خلف',
      MAX: 'الأعلى',
      SWAP_HHC_HC:"تبادل hHC إلى HC",
      SWAP:"تبادل",
      BALANCE: 'توازن:',
    },
  },
};
