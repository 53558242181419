export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_HOLD_HC: {
      BACK: 'Dos',
      MAX: 'Max',
      HOLD: 'Prise',
      BALANCE: 'ÉQUILIBRE:',
      GET:"Obtenir",
      AVAILABLE:"Disponible",
      YOU_GET:"Vous obtenez"
    },
  },
};
