export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'Назад',
      HOLD_HC:'Холд HC',
      HOLD_HHC:'Холд hHC',
      HOLD: 'ХОЛД',
      DAY: 'день',
      GET: 'ПОЛУЧИТЬ',
      INNER_BALANCE: 'Внутренний баланс',
      HHC_TO_HC: 'hHC в HC',
      TAKE_HC:'ЗАБРАТЬ HC',
      TAKE_HHC:'ЗАБРАТЬ hHC',
    },
  },
};
