export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_SWAP_HHC_ON_HC: {
      BACK: 'Назад',
      MAX: 'Макс',
      SWAP_HHC_HC:"Обмен hHC на HC",
      SWAP:"Обменять",
      BALANCE: 'БАЛАНС:',
    },
  },
};
