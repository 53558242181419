export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_SWAP_HHC_ON_HC: {
      BACK: 'Dos',
      MAX: 'Max',
      SWAP_HHC_HC:"Échanger hHC en HC",
      SWAP:"Échange",
      BALANCE: 'ÉQUILIBRE:',
    },
  },
};
