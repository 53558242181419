<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'energy'"
  src="/assets/coinIcon/energy.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'dai'"
  src="/assets/coinIcon/dai.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'forcecoin'"
  src="/assets/coinIcon/H.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-1'"
  src="/assets/coinIcon/H1.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-2'"
  src="/assets/coinIcon/H2.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-3'"
  src="/assets/coinIcon/H3.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-4'"
  src="/assets/coinIcon/H4.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-5'"
  src="/assets/coinIcon/H5.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-6'"
  src="/assets/coinIcon/H6.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-7'"
  src="/assets/coinIcon/H7.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'hHC-8'"
  src="/assets/coinIcon/H8.svg"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcr'"
  src="/assets/coinIcon/sfcr.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcr2'"
  src="/assets/coinIcon/sfcr2.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfc2'"
  src="/assets/coinIcon/sfc2.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcnew'"
  src="/assets/coinIcon/sfcNew.png"
  alt="" />

<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcold'"
  src="/assets/coinIcon/sfcOld.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'matic'"
  src="/assets/coinIcon/matic.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'rt'"
  src="/assets/coinIcon/rt.png"
  alt="" />
