export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_HOLD_HC: {
      BACK: 'خلف',
      MAX: 'الأعلى',
      HOLD: 'يمسك',
      BALANCE: 'توازن:',
      GET:"يحصل",
      AVAILABLE:"متاح",
      YOU_GET:"انت تحصل"
    },
  },
};
