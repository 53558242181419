export const locale = {
  lang: 'fr',
  data: {
    CONFIRM_GETHHC_MODAL: {
      TITLE: 'Attention',
      INFO: ' Etes-vous sûr de vouloir récupérer',
      INFO_1: ' Après avoir reçu ',
      INFO_2: ' la détention prendra fin et vous ne pourrez pas obtenir de  plus élevé.',
      AGREEMENT: 'Je confirme tout',
      YES: 'Oui',
      NO: 'Non',
      QUESTION:'Êtes-vous sûr de vouloir le récupérer'
    },
  },
};
