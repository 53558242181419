export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: '後退',
      HOLD_HC:'保持HC',
      HOLD_HHC:'保持hHC',
      HOLD: '抓住',
      DAY: '天',
      GET: '得到',
      INNER_BALANCE: '內在平衡',
      HHC_TO_HC: 'hHC 至 HC',
      TAKE_HC:'採取HC',
      TAKE_HHC:'採取hHC',
    },
  },
};
