export const locale = {
  lang: 'hi',
  data: {
    CONFIRM_GETHHC_MODAL: {
      TITLE: 'ध्यान दें',
      INFO: ' क्या आप निश्चित रूप से उठाना चाहते हैं',
      INFO_1: ' प्राप्त करने के बाद ',
      INFO_2: ' होल्डिंग खत्म हो जाएगी और आप उच्च एचएमएफ प्राप्त नहीं कर पाएंगे । ',
      AGREEMENT: 'मैं सब कुछ पुष्टि करता हूँ',
      YES: 'हाँ',
      NO: 'नहीं',
      QUESTION:'क्या आप वाकई इसे उठाना चाहते हैं'
    },
  },
};
