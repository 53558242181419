export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_WALLET_VALUE: {
      WALLET: 'Wallet',
      TOTAL_BALANCE: 'Total Balance',
      CONING_SOON: 'Coming soon',
      SEND: 'Send',
      RECEIVE: 'Receive',
      DEPOSIT: 'Deposit',
      SWAP: 'Swap',
      TRANSFER: 'Transfer',
      WALLET_BALANCE: 'WALLET BALANCE',
      BALANCE: 'INNER BALANCE',
      DISCONECT: 'Disconect',
      CANCEL: 'Cancel',
      REPLENISH: 'Replenish',
      SEND_TO_WALLET: 'Send to wallet',
      CHECK_NAME: 'Receive rewards to the wallet',
      EXTRA_WALLET: 'EXTRA WALLET',
      SELL: 'Sell HC',
      BUY: 'Buy HC',
      HOLD_HC:'Hold HC',
      TRADE: "Trade HC"
    },
  },
};
