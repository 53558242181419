export const locale = {
  lang: 'ur',
  data: {
    CONFIRM_GETHHC_MODAL: {
      TITLE: 'توجہ',
      INFO: ' کیا آپ کو یقین ہے کہ آپ اٹھانا چاہتے ہیں',
      INFO_1: ' وصول کرنے کے بعد ',
      INFO_2: ' ہولڈنگ ختم ہو جائے گی اور آپ اعلی  حاصل نہیں کر سکیں گے',
      AGREEMENT: 'میں سب کچھ تصدیق کرتا ہوں',
      YES: 'جی ہاں',
      NO: 'نہیں',
      QUESTION:'کیا آپ کو یقین ہے کہ آپ اسے اٹھانا چاہتے ہیں'
    },
  },
};
