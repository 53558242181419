export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'Mặt sau',
      HOLD_HC:'Giữ HC',
      HOLD_HHC:'Giữ hHC',
      HOLD: 'GIỮ',
      DAY: 'ngày',
      GET: 'LẤY',
      INNER_BALANCE: 'Cân bằng bên trong',
      HHC_TO_HC: 'hHC sang HC',
      TAKE_HC:'LẤY HC',
      TAKE_HHC:'LẤY hHC',
    },
  },
};
