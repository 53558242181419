export const locale = {
  lang: 'zh',
  data: {
    SIDE_NAV_HOLD_HC: {
      BACK: '後退',
      MAX: '最大限度',
      HOLD: '抓住',
      BALANCE: '平衡：',
      GET:"得到",
      AVAILABLE:"可用的",
      YOU_GET:"你正在得到"
    },
  },
};
