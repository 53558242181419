export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'واپس',
      HOLD_HC:'HC پکڑو',
      HOLD_HHC:'hHC پکڑو',
      HOLD: 'پکڑو',
      DAY: 'دن',
      GET: 'حاصل کریں',
      INNER_BALANCE: 'اندرونی توازن',
      HHC_TO_HC: 'HC سے hHC',
      TAKE_HC:'HC لے لو',
      TAKE_HHC:'hHC لے لو',
    },
  },
};
