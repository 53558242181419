export const locale = {
  lang: 'en',
  data: {
    SIDE_NAV_SWAP_HHC_ON_HC: {
      BACK: 'Back',
      MAX: 'Max',
      SWAP_HHC_HC:"Exchange hHC to HC",
      SWAP:"Exchange",
      BALANCE: 'BALANCE:',
    },
  },
};
