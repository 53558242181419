export const amoy = [
  {
    token: '',
    value: 0,
    name: 'DAI',
    secondName: 'Dai',
    costCoin: 1,
    changeCoin: 0,
    valuePayment: 0,
    img: 'dai',
  },
  {
    token: '',
    value: 0,
    name: 'HC',
    secondName: 'Forcecoin',
    costCoin: 0,
    changeCoin: 0,
    img: 'forcecoin',
  },
  {
    token: '',
    value: 0,
    name: 'GAS',
    secondName: 'Matic',
    costCoin: 0,
    changeCoin: 0,
    img: 'matic',
  },

  {
    token: '',
    value: 0,
    name: 'RES',
    secondName: 'Energy',
    costCoin: 0,
    changeCoin: 0,
    img: 'energy',
  },
  {
    token: '0x87db427ca711ce0c10506e868f361f1c0b3c7b87',
    value: 0,
    name: 'SFC',
    secondName: 'SFC old',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcold',
  },
  {
    token: '0x983ca224345019a19749b237d264b9fe33011eff',
    value: 0,
    name: 'SFC',
    secondName: 'SFC NEW',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcnew',
  },
  {
    token: '0x95e3ee078a4e0195150a212643b3311e851859b1',
    value: 0,
    name: 'SFC2',
    secondName: 'SFC2',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfc2',
  },
  {
    token: '0x903d1d538a39e729a22d519eb39b28c8607db2ea',
    value: 0,
    name: 'SFCr',
    secondName: 'SFCr',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcr',
  },
  {
    token: '0x26a85e34749b9f4fbcc88b4542cc69b881336f47',
    value: 0,
    name: 'SFCr2',
    secondName: 'SFCr2',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcr2',
  },
  {
    token: '0xf4cb9132f5b0ad3c358cc31af98537284a9c425b',
    value: 0,
    name: 'RT',
    secondName: 'Rating Token',
    costCoin: 0,
    changeCoin: 0,
    img: 'rt',
  },
];

export const poligon = [
  {
    token: '',
    value: 0,
    name: 'DAI',
    secondName: 'Dai',
    costCoin: 1,
    valuePayment: 0,
    changeCoin: 0,
    img: 'dai',
  },
  {
    token: '',
    value: 0,
    name: 'HC',
    secondName: 'Forcecoin',
    costCoin: 0,
    changeCoin: 0,
    img: 'forcecoin',
  },
  {
    token: '',
    value: 0,
    name: 'GAS',
    secondName: 'Matic',
    costCoin: 0,
    changeCoin: 0,
    img: 'matic',
  },

  {
    token: '',
    value: 0,
    name: 'RES',
    secondName: 'Energy',
    costCoin: 0,
    changeCoin: 0,
    img: 'energy',
  },
  {
    token: '0x59C0Aa7a197694C93B1d7dfbc7d124dEbBf8eD5D',
    value: 0,
    name: 'SFC',
    secondName: "SFC old'",
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcold',
  },
  {
    token: '0xCE5f928BE5e42f7f83e7C92a9dDE70430a119C44',
    value: 0,
    name: 'SFC',
    secondName: 'SFC NEW',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcnew',
  },
  {
    token: '0x6EE76404b3214C3C98A91d847CD7F15A745479BD',
    value: 0,
    name: 'SFC2',
    secondName: 'SFC2',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfc2',
  },
  {
    token: '0xD763c4c0A1d50c76067992bebaa24C9F786FDe68',
    value: 0,
    name: 'SFCr',
    secondName: 'SFCr',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcr',
  },
  {
    token: '0x167ad8bD31A1871231f4bC0D6ff762cB87Cae594',
    value: 0,
    name: 'SFCR2',
    secondName: 'SFCr2',
    costCoin: 0,
    changeCoin: 0,
    img: 'sfcr2',
  },
  {
    token: '0xA77ed4939578e3e4961fAAf54B53c1ddaF6512d3',
    value: 0,
    name: 'RT',
    secondName: 'Rating Token',
    costCoin: 0,
    changeCoin: 0,
    img: 'rt',
  },
];

export const hHCBalance = [
  {
    token: '',
    value: 0,
    name: 'hHC-1',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-1',
    entryLevel:1,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-2',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-2',
    entryLevel:2,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-3',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-3',
    entryLevel:3,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-4',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-4',
    entryLevel:4,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-5',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-5',
    entryLevel:5,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-6',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-6',
    entryLevel:6,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-7',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-7',
    entryLevel:7,
    isView: false,
    countExtend:1
  },
  {
    token: '',
    value: 0,
    name: 'hHC-8',
    costCoin: 0,
    changeCoin: 0,
    img: 'hHC-8',
    entryLevel:8,
    isView: false,
    countExtend:1
  },
]
export const paymentBalance = [
  {
    token: '',
    value: 0,
    name: 'HC',
    secondName: 'Forcecoin',
    costCoin: 0,
    changeCoin: 0,
    img: 'forcecoin',
  },
  {
    token: '',
    value: 0,
    name: 'HC',
    secondName: 'Vesting',
    costCoin: 0,
    changeCoin: 0,
    img: 'forcecoin',
  },
  {
    token: '',
    value: 0,
    valuePayment: 0,
    name: 'DAI',
    secondName: 'Dai',
    costCoin: 1,
    changeCoin: 0,
    img: 'dai',
  },
  {
    token: '',
    value: 0,
    name: 'RES',
    secondName: 'Energy',
    costCoin: 0,
    changeCoin: 0,
    img: 'energy',
  },
];
