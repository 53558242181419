export const locale = {
  lang: 'fr',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'Dos',
      HOLD_HC:'Maintenez HC',
      HOLD_HHC:'Maintenez hHC',
      HOLD: 'PRISE',
      DAY: 'jour',
      GET: 'OBTENIR',
      INNER_BALANCE: 'Équilibre intérieur',
      HHC_TO_HC: 'hHC vers HC',
      TAKE_HC:'PRENEZ HC',
      TAKE_HHC:'PRENEZ HHC',
    },
  },
};
