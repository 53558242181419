export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_HOLD_HC_WALLET: {
      BACK: 'वापस',
      HOLD_HC:'पकड़ो HC',
      HOLD_HHC:'पकड़ो hHC',
      HOLD: 'पकड़ो',
      DAY: 'दिन',
      GET: 'प्राप्त करें',
      INNER_BALANCE: 'आंतरिक संतुलन',
      HHC_TO_HC: 'hHC से HC',
      TAKE_HC:'ले लो HC',
      TAKE_HHC:'ले लो hHC',
    },
  },
};
